import React from 'react'

// Styles
import * as styles from './style'

// Helpers
import ImgUtil from '../../../utils/img.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils'

// Components
import GlobalContainer from '../globalContainer'
import Grid from '@material-ui/core/Grid'
import Button from '../button'

// Images
import HomepageHeroMobile from '../../../../static/steam-img/UiPath-Homepage-Hero-Image-Mobile.png'

const Hero = (props: any) => {
  let locationData =
    props.location && props.location.pathname ? props.location.pathname : '/'
  if (props.data) {
    let eyebrow: string = '',
      headline: string = '',
      name: string = '',
      theme: string = '',
      subheadline: string = '',
      cta:
        | { externalUrl: string; text: string; internalLink?: internalUrl }[]
        | undefined,
      focalPoint: { x: number; y: number } | undefined,
      imageSrc: string = '',
      altText: string = '',
      imageDimensions: { width: number; height: number } | undefined,
      pageType: string = '',
      solutionsImgSrc =
        'https://images.ctfassets.net/5965pury2lcm/1swgruP0ClYB0WhRUh9wfP/8e4a6d113015cee67e0a2f90cc1967b2/SolutionsHeroBG.png',
      companyListingImgSrc =
        'https://images.ctfassets.net/5965pury2lcm/4iUydleB0jjTj8pbuhs8I6/e0617e3ec9452106504949bc8339cd65/image_1.png',
      partnerPageHeroLogoUrl = '',
      useCase = 'Minimal',
      pageUseCase = '',
      metaInformation: any = {},
      partnerLogoAltText = '',
      backgroundAsset: any = null,
      backgroundVideo: any = null,
      locale: string = 'en-US'

    if (props.metaInformation && props.metaInformation[0]) {
      metaInformation = props.metaInformation[0]
    }

    if (
      props.pageUseCase == 'Events' &&
      props.data.backgroundVideo?.animationFile?.file?.url
    ) {
      backgroundVideo = props.data.backgroundVideo.animationFile.file.url
    }

    if (props.pageType) {
      pageType = props.pageType
    }

    if (props.locale) {
      locale = props.locale
    }

    if (props.pageUseCase) {
      pageUseCase = props.pageUseCase
    }
    if (props?.data?.useCase) {
      useCase = props.data.useCase
    }

    if (props?.data?.backgroundAsset) {
      backgroundAsset = props.data.backgroundAsset
    }

    // handles cases where we have a background image and no use case selected - HomepageHero & More
    if (useCase === 'Minimal' && backgroundAsset) useCase = 'Product Hero'

    if (useCase === 'Product Hero' && backgroundAsset) {
      if (backgroundAsset.focalPoint && backgroundAsset.focalPoint.focalPoint) {
        focalPoint = backgroundAsset.focalPoint.focalPoint
      }
      if (backgroundAsset.altText) {
        altText = backgroundAsset.altText
      }

      if (backgroundAsset.image && backgroundAsset.image.file) {
        if (
          backgroundAsset.image.file.details &&
          backgroundAsset.image.file.details.image
        ) {
          imageDimensions = backgroundAsset.image.file.details.image
        }
        if (backgroundAsset.image.file.url) {
          imageSrc = backgroundAsset.image.file.url
        }
      }
    }

    if (useCase === 'Centered text with dots') {
      imageSrc =
        'https://images.ctfassets.net/5965pury2lcm/5IHctXhjd9qqmvcpC5KQ3K/d3d95fbb5ce11b95e9ef1d2f98f14625/Hero_BG_fullcomp.png'
    }

    if (props.data.headline) {
      headline = props.data.headline
    }
    if (props.data.name) {
      name = props.data.name
    }

    if (props.data.heroTheme) {
      theme = props.data.heroTheme
    }

    if (pageType === 'partners') {
      partnerPageHeroLogoUrl =
        metaInformation?.colorLogo?.image?.file?.url || ''
      partnerLogoAltText = metaInformation?.colorLogo?.altText || ''
    }
    if (useCase === 'Minimal') {
      theme = 'light'
    }

    if (useCase === 'Minimal' && pageType === 'webinarRecording') {
      theme = 'dark'
    }

    // enable subheadline for use cases that have it
    if (
      useCase === 'Minimal' ||
      pageType === 'solutions' ||
      pageType === 'aboutRPA' ||
      pageType === 'partners' ||
      pageType === 'flex' ||
      pageType === 'thankYou' ||
      pageType === 'companyListing' ||
      pageType === 'resourceListing' ||
      pageType === 'legal'
    ) {
      if (props.data.subheadline && props.data.subheadline.subheadline) {
        subheadline = props.data.subheadline.subheadline
      }
    }

    const isHomePage = props.isHomePage ? props.isHomePage : false
    //Set only product variables
    let backgroundProps = {}

    if (useCase !== 'Minimal') {
      if (props.data.cta) {
        cta = props.data.cta
      }
      if (props.data.eyebrow) {
        eyebrow = props.data.eyebrow
      }

      let backgroundImgToUse = ''

      if (pageType === 'solutions') {
        backgroundImgToUse = solutionsImgSrc
      } else if (pageType === 'companyListing') {
        backgroundImgToUse = companyListingImgSrc
      } else {
        backgroundImgToUse = imageSrc
      }

      // only set background props if not an event video background
      if (!backgroundVideo) {
        const backgroundCoordinateX =
          focalPoint && focalPoint.x && imageDimensions && imageDimensions.width
            ? (focalPoint.x / imageDimensions.width) * 100
            : '50'
        const backgroundCoordinateY =
          focalPoint &&
          focalPoint.y &&
          imageDimensions &&
          imageDimensions.height
            ? (focalPoint.y / imageDimensions.height) * 100
            : '50'

        const gravityX =
          focalPoint && focalPoint.x && imageDimensions && imageDimensions.width
            ? focalPoint.x / imageDimensions.width
            : '0.50'
        const gravityY =
          focalPoint &&
          focalPoint.y &&
          imageDimensions &&
          imageDimensions.height
            ? focalPoint.y / imageDimensions.height
            : '0.50'

        const mobileImgSrc = isHomePage
          ? `width=1920`
          : `width=768,height=270,fit=cover,gravity=${gravityX}x${gravityY}`

        backgroundProps = {
          $img: ImgUtil.getResizedImageUrl(backgroundImgToUse, `width=3840`),
          $tablet_img: ImgUtil.getResizedImageUrl(
            backgroundImgToUse,
            `width=1920`
          ),
          $mobile_img: ImgUtil.getResizedImageUrl(
            backgroundImgToUse,
            mobileImgSrc
          ),
          $mobile_xs_img: isHomePage ? HomepageHeroMobile : undefined,
          $backgroundPosX: backgroundCoordinateX,
          $backgroundPosY: backgroundCoordinateY,
        }
      }
    }

    return (
      <styles.heroOuter
        {...backgroundProps}
        $heroTheme={theme}
        $isHomePage={isHomePage}
        $showBreadcrumbs={props.showBreadcrumbs}
        $useCase={useCase}
        $pageType={pageType}
        $isEventVideo={backgroundVideo ? true : false}
        data-cy="Hero"
        data-cyType={pageType}
      >
        <GlobalContainer className="Hero__Container">
          {backgroundVideo && (
            <>
              <div className="Hero__Gradient"></div>
              <video
                muted={true}
                autoPlay={true}
                loop={true}
                playsInline={true}
                className="Hero__Video"
              >
                <source src={backgroundVideo} />
              </video>
            </>
          )}
          {props.showBreadcrumbs && (
            <styles.styledBreadcrumbs
              theme={theme}
              currentPathname={locationData}
              pageName={props.pageTitle}
              pageType={pageType}
              locale={locale}
            />
          )}
          <styles.heroInner className="heroInner">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={isHomePage ? 12 : 8}
                className="Hero__Grid"
              >
                {eyebrow && (
                  <styles.heroEyebrowContainer>
                    <styles.heroEyebrow>{eyebrow}</styles.heroEyebrow>
                  </styles.heroEyebrowContainer>
                )}
                {pageUseCase === 'Business Partner Detail' &&
                  useCase === 'Centered text with dots' &&
                  partnerPageHeroLogoUrl && (
                    <img
                      src={ImgUtil.getResizedImageUrl(
                        partnerPageHeroLogoUrl,
                        `width=150`
                      )}
                      alt={partnerLogoAltText}
                    />
                  )}
                {headline && (
                  <styles.heroTitle type="header1">
                    {formatInlineCode(headline)}
                  </styles.heroTitle>
                )}
                {pageType &&
                  useCase &&
                  pageType === 'solutions' &&
                  useCase !== 'Minimal' && (
                    <div className="Hero__Solutions-Dotted-Container">
                      <div className="Hero__Solutions-Img-Circle-Robot">
                        <div className="Hero__Solutions-Img-Circle">
                          {imageSrc && (
                            <styles.SolutionsHeroCircle
                              $backround={ImgUtil.getResizedImageUrl(
                                imageSrc,
                                `height=384`
                              )}
                            ></styles.SolutionsHeroCircle>
                          )}
                        </div>
                        <img
                          src={ImgUtil.getResizedImageUrl(
                            'https://images.ctfassets.net/5965pury2lcm/O01jnRaKE15bgX6IVkgt1/e985f99b12c0e2546cef6b62e3ff067a/robot_spotlight_1x.png',
                            `width=150`
                          )}
                          alt={altText}
                          className="Hero__Solutions-Img-Robot"
                        />
                      </div>
                    </div>
                  )}
                {subheadline && (
                  <styles.heroSubheadline className="Lead">
                    {formatInlineCode(subheadline)}
                  </styles.heroSubheadline>
                )}
                {cta && (
                  <div className="MultiBodyHeroHeader-CTAs">
                    {cta[0] && (
                      <Button
                        icon={true}
                        href={
                          cta[0].internalLink
                            ? internalLinkHandler(cta[0].internalLink)
                            : cta[0].externalUrl
                        }
                        text={cta[0].text ? cta[0].text : ''}
                        type={
                          isHomePage && theme === 'dark'
                            ? 'secondary'
                            : 'primary'
                        }
                        size={isHomePage ? 'medium' : 'small'}
                        theme={isHomePage && theme === 'dark' ? 'light' : theme}
                      />
                    )}

                    {cta[1] && (
                      <Button
                        icon={true}
                        href={
                          cta[1].internalLink
                            ? internalLinkHandler(cta[1].internalLink)
                            : cta[1].externalUrl
                        }
                        text={cta[1].text ? cta[1].text : ''}
                        type="tertiary"
                        className="Hero__Secondary-CTA"
                        size={isHomePage ? 'medium' : 'small'}
                        theme={theme}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </styles.heroInner>
        </GlobalContainer>
      </styles.heroOuter>
    )
  }
  return null
}

export default Hero
