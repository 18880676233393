export const pageSectionMap = {
  'en-US': {
    blog: {
      '/blog': { name: 'Blog', sectionUrl: '/blog' },
      '/blog/rpa': { name: 'RPA', sectionUrl: '/blog/rpa' },
      '/blog/robotic-process-automation': {
        name: 'RPA',
        sectionUrl: '/blog/rpa',
      },
      '/blog/ai': { name: 'Artificial Intelligence', sectionUrl: '/blog/ai' },
      '/blog/artificial-intelligence': {
        name: 'Artificial Intelligence',
        sectionUrl: '/blog/ai',
      },
      '/blog/industry-solutions': {
        name: 'Industry Solutions',
        sectionUrl: '/blog/industry-solutions',
      },
      '/blog/product-and-updates': {
        name: 'Product',
        sectionUrl: '/blog/product-and-updates',
      },
      '/blog/digital-transformation': {
        name: 'Digital Transformation',
        sectionUrl: '/blog/digital-transformation',
      },
      '/blog/automation': {
        name: 'Automation',
        sectionUrl: '/blog/automation',
      },
    },
    global: {
      '/product': { name: 'Product', sectionUrl: '/product' },
      '/solutions': {
        name: 'Solutions',
        sectionUrl: '/solutions',
      },
      '/solutions/industry': {
        name: 'By Industry',
        sectionUrl: '/solutions/industry',
      },
      '/solutions/department': {
        name: 'By Department',
        sectionUrl: '/solutions/department',
      },
      '/solutions/technology': {
        name: 'By Technology',
        sectionUrl: '/solutions/technology',
      },
      '/company': {
        name: 'About Us',
        sectionUrl: '/company/about-us',
      },
      '/leadership': {
        name: 'About Us',
        sectionUrl: '/company/about-us',
      },
      '/newsroom': {
        name: 'Newsroom',
        sectionUrl: '/newsroom',
      },
      '/resources': {
        name: 'Resources',
        sectionUrl: '/resources/all',
      },
      '/resources/all': {
        name: 'Resources',
        sectionUrl: '/resources/all',
      },
      '/resources/automation-case-studies': {
        name: 'Automation Case Studies',
        sectionUrl: '/resources/automation-case-studies',
      },
      '/resources/automation-demo': {
        name: 'Automation Demo',
        sectionUrl: '/resources/automation-demo',
      },
      '/resources/automation-webinars': {
        name: 'Automation Webinars',
        sectionUrl: '/resources/automation-webinars',
      },
      '/rpa/journey': { name: 'RPA Journey', sectionUrl: '/rpa/journey' },
      '/partners': {
        name: 'Partners',
        sectionUrl: '/partners',
      },
      '/partners/become-a-partner': {
        name: 'Become a partner',
        sectionUrl: '/partners/become-a-partner',
      },
      '/legal/trust-and-security': {
        name: 'Trust and Security',
        sectionUrl: '/legal/trust-and-security',
      },
    },
  },
  'ja-JP': {
    global: {
      '/product': { name: '製品', sectionUrl: '/ja/product' },
      '/solutions': {
        name: 'ソリューション',
        sectionUrl: '/ja/solutions',
      },
      '/solutions/industry': {
        name: '業界別',
        sectionUrl: '/ja/solutions/industry',
      },
      '/solutions/department': {
        name: '部門別',
        sectionUrl: '/ja/solutions/department',
      },
      '/solutions/technology': {
        name: 'テクノロジー別',
        sectionUrl: '/ja/solutions/technology',
      },
      '/company': {
        name: '会社',
        sectionUrl: '/ja/company/about-us',
      },
      '/newsroom': {
        name: 'ニュースルーム',
        sectionUrl: '/ja/newsroom',
      },
      '/resources': {
        name: '資力',
        sectionUrl: '/ja/resources/all',
      },
      '/resources/all': {
        name: '資力',
        sectionUrl: '/ja/resources/all',
      },
      '/resources/automation-case-studies': {
        name: '自動化のケーススタディ',
        sectionUrl: '/ja/resources/automation-case-studies',
      },
      '/resources/automation-demo': {
        name: '自動化デモ',
        sectionUrl: '/ja/resources/automation-demo',
      },
      '/resources/automation-webinars': {
        name: '自動化ウェビナー',
        sectionUrl: '/ja/resources/automation-webinars',
      },
      '/rpa/journey': { name: 'RPAについて', sectionUrl: '/ja/rpa/journey' },
      '/partners': {
        name: 'UiPathパートナ',
        sectionUrl: '/ja/partners',
      },
      '/partners/become-a-partner': {
        name: '協力者になる',
        sectionUrl: '/ja/partners/become-a-partner',
      },
    },
  },
  'de-DE': {
    global: {
      '/product': { name: 'Produkt', sectionUrl: '/de/product' },
      '/solutions': {
        name: 'Lösungen',
        sectionUrl: '/de/solutions',
      },
      '/partners': {
        name: 'Partner',
        sectionUrl: '/de/partners',
      },
      '/rpa/journey': { name: 'RPA-Reise', sectionUrl: '/de/rpa/journey' },
    },
  },
  'fr-FR': {
    global: {
      '/product': { name: 'Produits', sectionUrl: '/fr/product' },
      '/solutions': {
        name: 'Solutions',
        sectionUrl: '/fr/solutions',
      },
      '/partners': {
        name: 'Partner',
        sectionUrl: '/fr/partners',
      },
      '/rpa/journey': {
        name: 'A propos de la RPA',
        sectionUrl: '/fr/rpa/journey',
      },
    },
  },
  'ko-KR': {
    global: {
      '/product': { name: '제품', sectionUrl: '/ko/product' },
      '/solutions': {
        name: '솔루션',
        sectionUrl: '/ko/solutions',
      },
      '/partners': {
        name: '파트너',
        sectionUrl: '/ko/partners',
      },
      '/rpa/journey': { name: 'RPA 여정', sectionUrl: '/ko/rpa/journey' },
    },
  },
}
