import styled from 'styled-components'
import Breadcrumbs from '../breadcrumbs'
import Typography from '../typography'

export const styledBreadcrumbs = styled(Breadcrumbs)``

export const heroInner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 32px 0 64px 0;
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 48px 0;
  }
`

export const heroTitle = styled(Typography)`
  margin: 0;
  text-shadow: none;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`
export const heroEyebrowContainer = styled.div`
  border-radius: 16px;
  margin-bottom: 8px;
  height: 30px;
  padding: 0px 16px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down('xs')} {
    margin-bottom: 16px;
  }
`

export const heroEyebrow = styled.p`
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
  ${(p) => p.theme.breakpoints.down('xs')} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const heroSubheadline = styled.p`
  margin-top: 16px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: 32px;
  ${(p) => p.theme.breakpoints.down('md')} {
    margin-top: 8px;
  }
  ${(p) => p.theme.breakpoints.down('xs')} {
    margin-top: 16px;
  }
`

export const heroOuter = styled.div`
  ${({
    $img,
    $backgroundPosX,
    $backgroundPosY,
    $mobile_img,
    $tablet_img,
    theme,
    $heroTheme,
    $useCase,
    $isEventVideo,
    $mobile_xs_img,
    $pageType,
  }) => {
    let themeStyles,
      pageSpecificStyles,
      bgImgStyle,
      heroInnerPadding,
      pagePadding

    let companyListingStyles = ''

    if ($heroTheme === 'dark') {
      const pageSpecificBG =
        $pageType === 'home'
          ? theme.webMktPalette.background.white
          : theme.webMktPalette.background.dark
      themeStyles = `
        ${heroEyebrowContainer} {
          background: ${theme.webMktPalette.background.white};
          p {
            color: ${theme.webMktPalette.text.primaryBlack};
          }
        }
        ${heroTitle},${heroSubheadline} {
          color: ${theme.webMktPalette.text.primaryWhite};
        }
        ${
          $img
            ? `${theme.breakpoints.down('xs')} {
            background-color: ${pageSpecificBG};
          }`
            : `background-color: ${pageSpecificBG};`
        }
      `
    }
    if ($heroTheme === 'light') {
      /*
        There is only light/dark theme for component Hero, if
        the $useCase in minimal the bgcolor would be #f7f7f7
      */
      const bgColor =
        $useCase === 'Product Hero'
          ? '#e1e2e4'
          : theme.webMktPalette.background.gray

      const pageSpecificBG =
        $pageType === 'home' ? theme.webMktPalette.background.white : bgColor
      themeStyles = `
        ${heroEyebrowContainer} {
          background: #111212;
          p {
            color: ${theme.webMktPalette.text.primaryWhite};
          }
        }
        ${heroTitle} {
          color: ${theme.webMktPalette.text.primaryBlack};
        }
        ${
          $img
            ? `${theme.breakpoints.down('xs')} {
            background-color: ${pageSpecificBG};
          }`
            : `background-color:  ${pageSpecificBG};`
        }
      `
    }
    if ($pageType === 'solutions') {
      pageSpecificStyles = `
        background-repeat: no-repeat;
        background-position: center;
        ${theme.breakpoints.down('xs')} {
          background: ${theme.webMktPalette.background.white};
        }
        ${heroInner} {
          ${theme.breakpoints.down('xs')} {
            margin-top: 278px;
          }
        }
        .Hero__Grid {
          ${theme.breakpoints.down('sm')} {
            max-width: 454px;
          }
          ${theme.breakpoints.down('xs')} {
            max-width: 100%;
          }
        }
        .MultiBodyHeroHeader-CTAs {
          .CTA {
            @media screen and (max-width: 360px) {
              width: 100%;
            }
          }
        }
        .Hero__Container {
          position: relative;
        }
        .Hero__Solutions-Dotted-Container {
          position: absolute;
          right: -360px;
          top: 0;
          height: 100%;
          width: 588px;
          background-image: radial-gradient(rgba(0,0,0,.25) 1px,transparent 0);
          background-size: 20px 20px;
          ${theme.breakpoints.down('lg')} {
            width: 364px;
            right: -160px;
          }
          ${theme.breakpoints.down('sm')} {
            width: 275px;
            right: -100px;
          }
          ${theme.breakpoints.down('xs')} {
            width: 100%;
            height: 182px;
            right: 0;
            top: 90px;
          }

          .Hero__Solutions-Img-Circle-Robot {
            height: 384px;
            width: 384px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            ${theme.breakpoints.down('lg')} {
              height: 304px;
              width: 304px;
            }
            ${theme.breakpoints.down('sm')} {
              height: 266px;
              width: 266px;
              left: 0;
            }
            ${theme.breakpoints.down('xs')} {
              left: 50%;
              transform: translateX(-50%);
              top: -42px;
            }
          }
          .Hero__Solutions-Img-Robot {
            position: absolute;
            bottom: 0;
            left: -102px;
            height: auto;
            width: 79px;
            ${theme.breakpoints.down('sm')} {
              width: 76px;
              left: -102px;
            }
            ${theme.breakpoints.down('xs')} {
              left: 0;
            }

          }
          .Hero__Solutions-Img-Circle {
            border-radius: 192px;
            overflow: hidden;
            height: 100%;
            width: 100%;
            position: relative;
            left: -102px;
            ${theme.breakpoints.down('xs')} {
              left: 0;
            }
          }
        }
      `
    } else if ($pageType === 'home') {
      pageSpecificStyles = `
        min-height: 600px;
        ${theme.breakpoints.down('md')} {
          min-height: 480px;
        }
        ${theme.breakpoints.down('sm')} {
          min-height: 400px;
          padding-bottom: 0;
        }
        ${theme.breakpoints.down('xs')} {
          background-size: auto 300px;
          padding-bottom: 48px;
        }
        @media(max-width: 360px) {
          background-image: url(${$mobile_xs_img});
        }
        ${heroTitle} {
          font-size: 44px;
          line-height: 48px;
          ${theme.breakpoints.down('md')} {
            font-size: 40px;
            line-height: 44px;
          }
          ${theme.breakpoints.down('sm')} {
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0;
          }
          ${theme.breakpoints.down('xs')} {
            font-size: 28px;
            line-height: 36px;
          }
          @media(max-width: 500px) {
            .LineBreak {
              &:not(:nth-child(1)) {
                display: inline;
              }
            }
          }
        }
        ${heroTitle},${heroSubheadline}{
          ${theme.breakpoints.down('xs')} {
            color: ${theme.webMktPalette.text.primaryBlack};
          }
        }
        .Hero__Container {
          ${theme.breakpoints.down('sm')} {
            max-width: 100%;
          }
        }
        ${heroInner} {
          justify-content: flex-start;
          ${theme.breakpoints.down('xs')} {
            margin-top: 324px;
          }
        }
        .LineBreak {
          ${theme.breakpoints.down('xs')} {
            display: block;
          }
        }
      `
    } else if ($pageType === 'resourceListing') {
      const mobileBackgroundStyles =
        $heroTheme === 'light'
          ? `background: ${theme.webMktPalette.background.gray};
        background-image: none;`
          : ``

      let backgroundColor = $isEventVideo
        ? `background: ${theme.webMktPalette.background.black};`
        : ''

      pageSpecificStyles = `
        min-height: 344px;
        ${backgroundColor}
        ${theme.breakpoints.down('md')} {
          min-height: 352px;
        }
        ${theme.breakpoints.down('xs')} {
          min-height: 0;
          ${mobileBackgroundStyles}
        }

        ${heroInner} {
          justify-content: center;
          ${theme.breakpoints.down('xs')} {
            margin-top: 0;
            padding-top: 32px;
          }
        }

        .MultiBodyHeroHeader-CTAs {
          display: none;
        }

        .Hero__Grid {
          max-width: 587px;
          ${theme.breakpoints.down('md')} {
            max-width: 468px;
          }
          ${theme.breakpoints.down('sm')} {
            max-width: 368px;
          }
          ${theme.breakpoints.down('xs')} {
            max-width: 100%;
          }
        }

        .Hero__Container {
          position: relative;

          ${heroInner}, ${styledBreadcrumbs} {
            z-index: 6;
          }
        }

        .Hero__Gradient, .Hero__Video {
          position: absolute;
          height: 100%;
          max-width: calc(100% + (1920px - 100%) / 2 - 24px);
          width calc(100% + (100vw - 100%) / 2);
          top: 0;
          left: 24px;

          ${theme.breakpoints.down('xs')} {
            display: none;
          }
        }

        .Hero__Gradient {
          background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%);
          z-index: 5;
        }
        .Hero__Video {
          z-index: 4;
          object-fit: cover;
        }
      `
    } else if ($useCase === 'Centered text with dots') {
      themeStyles += `
        ${heroInner} {
          padding: 60px 0;
        }
        ${heroEyebrowContainer} {
          background: none;
          width: 100%;
          p {
            color: #595A5C;
          }
        }

        .MuiGrid-root {
          justify-content: center;
        }

        .MuiGrid-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

      `
    } else {
      pageSpecificStyles = `
        ${
          $useCase !== 'Minimal' &&
          `
          min-height: 500px;
          ${theme.breakpoints.down('md')} {
            min-height: 364px;
            // padding-bottom: 68px;
          }
          ${theme.breakpoints.down('xs')} {
            background-size: auto 270px;
            .MultiBodyHeroHeader-CTAs {
              width: 100%;
              .CTA {
                width: 100%;
              }
            }
          }
          ${heroInner} {
            justify-content: center;
            ${theme.breakpoints.down('xs')} {
              margin-top: 230px;
            }
          }
          `
        }
      `
    }

    if ($img && $useCase !== 'Centered text with dots') {
      bgImgStyle = `
        background-image: url(${$img});
        background-position: ${$backgroundPosX}% ${$backgroundPosY}%;
        background-size: cover;
        ${theme.breakpoints.down('md')} {
          background-image: url(${$tablet_img});
          min-height: 364px;
        }
        ${theme.breakpoints.down('xs')} {
          background-image: url(${$mobile_img});
          background-repeat: no-repeat;
          background-position-y: top;
        }
        ${heroInner} {
          ${theme.breakpoints.down('xs')} {
            margin-top: 230px;
          }
        }
      `
    } else if ($useCase === 'Centered text with dots') {
      bgImgStyle = `
        background-image: url(${$img});
        background-position: 50% 0;
        background-size: cover;
      `
    } else {
      bgImgStyle = ``
    }

    let minimal = `
      padding: 56px 0 48px;
      ${theme.breakpoints.down('sm')} {
        padding: 32px 0 36px;
      }
      ${theme.breakpoints.down('xs')} {
        padding: 27px 0 32px;
      }
    `
    let product = `
      padding: 56px 0 96px 0;
      ${theme.breakpoints.down('md')} {
        padding: 32px 0 64px 0;
      }
      ${theme.breakpoints.down('xs')} {
        padding: 48px 0;
      }
    `
    let hompage = `
      padding: 128px 0 96px 0;
      ${theme.breakpoints.down('md')} {
        padding: 96px 0 164px 0;
      }
      ${theme.breakpoints.down('sm')} {
        padding: 96px 0 120px 0;
      }
      ${theme.breakpoints.down('xs')} {
        padding: 0;
      }
    `

    let dottedHeroPadding = `
      padding-bottom: 64px;
      min-height: 360px;
    `

    if ($useCase === 'Minimal') {
      pagePadding = minimal
    } else if ($pageType === 'home') {
      pagePadding = hompage
    } else if ($useCase === 'Centered text with dots') {
      pagePadding = dottedHeroPadding
    } else {
      pagePadding = product
    }

    heroInnerPadding = `
      ${heroInner} {
        ${pagePadding}
      }
      ${styledBreadcrumbs} {
        padding-top: 24px;
        width: 100%;
        ${theme.breakpoints.down('xs')} {
          padding-top: 16px;
        }
      }
    `
    if ($pageType === 'companyListing') {
      companyListingStyles = `
        min-height: 336px;
        ${theme.breakpoints.down('md')} {
          min-height: 312px;
        }
        ${theme.breakpoints.down('sm')} {
          min-height: 224px;
        }
      `
    }

    return `
      ${themeStyles}
      ${bgImgStyle}
      ${heroInnerPadding}
      ${pageSpecificStyles}
      ${companyListingStyles}

      ${
        $pageType === 'home'
          ? `
      && {
        .Hero__Secondary-CTA {
          ${theme.breakpoints.down('xs')} {
            background: none;
            color: ${theme.webMktPalette.text.primaryBlack};
            border: 2px solid #000000;
            .CTA__Arrow-Container {
              svg {
                #ArrowPath {
                  fill: #000000;
                }
              }
            }
            &:hover {
              background: rgb(0 0 0 / .2);
              color: ${theme.webMktPalette.text.primaryBlack};
              border: 2px solid #000000;
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #000000;
                  }
                }
              }
            }
            &:active {
              background: rgb(0 0 0 / .2);
              color: #9D9D9D;
              border: 2px solid #000000;
              .CTA__Arrow-Container {
                svg {
                  #ArrowPath {
                    fill: #9D9D9D;
                  }
                }
              }
            }
          }
        }
      }`
          : ``
      }

      max-width: 1920px;
      margin: 0 auto;
      display: flex;

      .CTA:nth-child(2) {
        margin-left: 32px;
      }

      ${heroTitle},${heroSubheadline},${heroEyebrowContainer} {
        max-width: 972px;
      }

      .MultiBodyHeroHeader-CTAs {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        ${theme.breakpoints.down('xs')} {
          flex-direction: column;
          .CTA {
            justify-content: space-between;
            width: 100%;
          }
          .CTA:nth-child(2) {
            margin-left: 0;
            margin-top: 32px;
          }
        }
      }
      .Hero__Container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        ${theme.breakpoints.down('xs')} {
          max-width: 736px;
        }
      }
    `
  }}
`

export const SolutionsHeroCircle = styled.div`
  ${({ $backround, theme }) => {
    return `
    background-image: url('${$backround}');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    bottom: 0;
    ${theme.breakpoints.down('lg')} {
      background-position-x: 50%;
    }
    ${theme.breakpoints.down('sm')} {
      background-position-x: 50%;
    }
  `
  }}
`
