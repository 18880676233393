import styled from 'styled-components'
export const BreadcrumbOuter = styled.div`
  ${({ breadcrumbTheme, theme, pageType }) => {
    let linkStyles, iconStyles, homeIconStyles
    let thankYouStyles = ''

    if (breadcrumbTheme === 'light') {
      linkStyles = `
        color: rgb(89, 90, 92);
        &--Current {
          color: rgba(0, 0, 0, 0.3);
        }
        &:not(.Breadcrumbs__Link--Current) {
          &:hover {
            color: rgb(0, 103, 223);
          }
        }
      `
      iconStyles = `
        svg {
          #icon {
            fill: #595a5c;
          }
        }
      `
      homeIconStyles = `
        &:hover {
          svg {
            #icon {
              fill: ${theme.webMktPalette.icon.blueLight};
            }
          }
        }
      `
    }

    if (breadcrumbTheme === 'dark') {
      linkStyles = `
        color: rgb(255, 255, 255);
        &--Current {
          color: rgba(255, 255, 255, 0.3);
        }
        &:not(.Breadcrumbs__Link--Current) {
          &:hover {
            color: rgb(56, 198, 244);
          }
        }
      `
      iconStyles = `
        svg {
          #icon {
            fill: ${theme.webMktPalette.icon.white};
          }
        }
      `
      homeIconStyles = `
        &:hover {
          svg {
            #icon {
              fill: ${theme.webMktPalette.icon.blueDark};
            }
          }
        }
      `
    }

    if (pageType === 'thankYou') {
      thankYouStyles = `
        display: none;
        ${theme.breakpoints.down('sm')} {
          display: flex;
        }
        ${theme.breakpoints.down('xs')} {
          display: flex;
        }
      `
    }

    return `
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 1;
    .Breadcrumbs__Icon {
      &:not(.Breadcrumbs__Home-Icon) {
        margin: 0 8px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      ${iconStyles}
    }
    .Breadcrumbs__Home-Icon {
      display: flex;
      width: 16px;
      height: 16px;
      ${homeIconStyles}
    }
    .Breadcrumbs__Link {
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 16px;
      &:not(.Breadcrumbs__Link--Current) {
        cursor: pointer;
      }
      transition: 0.1s all cubic-bezier(0.4, 0.0, 0.2, 1);
      ${linkStyles}
      &:not(.Breadcrumbs__Link--Current) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    ${thankYouStyles}
    `
  }}
`
